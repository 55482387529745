import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './PromoTicker.css';

const PromoTicker = ({ promos }) => {
  const [selectedPromo, setSelectedPromo] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiImg = process.env.REACT_APP_API_IMG;

  const handleClick = (promo) => {
    setSelectedPromo(promo);
  };

  const handleClose = () => {
    setSelectedPromo(null);
  };

  const TextRender = ({ htmlContent }) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  };


  return (
    <>
      {/* Barra de Promoções */}
      <div className="promo-ticker-container">
        <div className="promo-ticker-track">
          {promos.map((promo) => (
            <span
              key={promo.id}
              className="promo-ticker-item"
              onClick={() => handleClick(promo)}
            >
              {promo.title}
            </span>
          ))}
        </div>
      </div>

      {/* Modal usando React-Bootstrap */}
      <Modal
        show={!!selectedPromo}
        onHide={handleClose}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{selectedPromo?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>{selectedPromo?.sub}</h3>
          <TextRender htmlContent={selectedPromo?.text} />
          <div className="promotion-img">
            <img src={`${apiImg}/assets/promotions/${selectedPromo?.foto}`} alt="Promotion" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PromoTicker;