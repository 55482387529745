import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import './About.css';
// import VideoPlayer from './VideoPlayer';

const About = () => {
  return (
    <Container className="about-section">
      <Row>
        <Col md={12}>
          <div className="video-wrapper">
            <video width="100%" autoPlay muted loop>
              <source src="https://gigabytecdn.multtv.tv.br//GIGABYTETV.m3u8" type="video/mp4" />
              Seu navegador não suporta o elemento de vídeo.
            </video>
            {/* <VideoPlayer /> */}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="mt-4">
          <h2>QUEM SOMOS</h2>
          <p>
            A Melo Telecomunicações, conhecida como Gigabyte Telecomunicações é uma empresa de internet banda larga, jovem que foi criada no ano de 2006, pelo empresário e empreendedor Walter Alves de Melo, com o intuito de oferecer aos seus clientes um serviço de qualidade e segurança.
          </p>
          <p>
            A Melo Telecomunicações atua em diversas cidades do Norte goiano e busca sempre estar à frente quando o assunto é velocidade. É uma empresa que procura constantemente conhecer e aplicar novas tecnologias.
          </p>
          <p>
            Dentre tantas tecnologias, inovações e projetos de expansão a Gigabyte no ano de 2014, recebe a licença da Anatel o: SEAC a liberação para atuar como Operadora de TV por Assinatura.
          </p>
          <p>
            No final de 2016, a empresa recebe também a liberação do STFC, para prestar serviço de Telefonia Fixa e agora em 2018 ela fará portabilidade entre outras operadoras.
          </p>
          <p>
            Mesmo tendo essas licenças no ano de 2014 e 2016, será no ano de 2018 que a empresa iniciará esses novos projetos, foram anos de estudo, investimento, conformidade legal, aprendizado e busca de tecnologias eficazes para se iniciar mais uma nova etapa da Gigabyte. Se tornando assim até o final de 2018 uma empresa Triple Play.
          </p>
          
          <h3>MISSÃO</h3>
          <p>
            A missão da Melo Telecomunicações não é apenas de venda é também de responsabilidade social, porque oferece aos seus clientes produtos de qualidade com garantia, contribuindo para um crescimento digital responsável, se tornando assim uma empresa de alta qualidade no ramo da internet via rádio e internet via cabo.
          </p>
          <p>
            Para manter-se extremamente competitiva no mercado a empresa oferece valores baixos e promoções constantes, buscando sempre novos clientes e assegurar os antigos, para que isso ocorra ela conta com produtos de alta qualidade e técnicos qualificados.
          </p>

          <h3>OBJETIVOS</h3>
          <p>
            Buscar sempre a qualidade;
            <br /> Integridade com os seus fornecedores;
            <br /> Superar resultados e expectativa;
            <br /> Confiabilidade;
            <br /> Credibilidade;
            <br /> Melhoria e inovação constante;
            <br /> Ampliação de novas tecnologias para atender o seu cliente;
            <br /> Estar em conformidade com as normas da ANATEL.
          </p>

          <h3>NATUREZA E RAMO DE ATUAÇÃO</h3>
          <p>
            A Empresa trabalha com internet banda larga oferecendo duas tecnologias:
          </p>
          <p>
            <strong>INTERNET VIA RÁDIO:</strong> consiste em distribuir o sinal de internet utilizando antenas e distribuindo através de Pops (Point of Presence) espalhados pela cidade, formando uma grande rede.
          </p>
          <p>
            <strong>INTERNET VIA CABO ÓPTICO:</strong> é um meio de transmissão que permite o tráfego de dados com velocidade muito próxima à velocidade da luz, que torna a fibra uma das tecnologias de transmissão mais modernas do mundo.
          </p>
          <p>
            <strong>TV POR ASSINATURA:</strong> Recebemos o sinal das programadoras com todos os canais assinados através de um circuito fechado via satélite esse sinal passa dentro do Headend local, que é enviado direto para sua casa via fibra óptica.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
