
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Movel from '../../pages/Movel/Movel';
import Chip from '../../assets/images/Leonardo_.jpg';

import './AppPhone.css';

/**
 * Componente que mostra um aviso de implementação
 * e permite voltar ao site principal (https://gigabytetelecom.com.br).
 */
function AppPhone() {
  // Controla se o Modal está visível
  const [showModal, setShowModal] = useState(true);

  // Ao fechar o modal, redireciona para a página principal
  const handleClose = () => {
    window.location.href = '/';
  };

  return (
    <div>
      <Movel />
      {/* Modal abre automaticamente, pois showModal inicia como true */}
      <Modal show={showModal} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton={handleClose}>
          <Modal.Title>Em Desenvolvimento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Olá! Nosso AppFone ainda está em implementação. Em breve, teremos novidades para você!</p>
          <div className='chip'>
            <img src={Chip} alt="" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Voltar para o site
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AppPhone;
