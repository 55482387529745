import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PromoTicker from './PromoTicker';
import { Spinner } from 'react-bootstrap';

const PromoTickerContainer = () => {
  const [promos, setPromos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPromos = async () => {
      try {
        // Altere a URL conforme o endpoint do seu backend
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/promotions`);
        setPromos(response.data); // Presumindo que a resposta seja um array de promoções
      } catch (error) {
        console.error('Erro ao buscar promoções:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPromos();
  }, []);

  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return <PromoTicker promos={promos} />;
};

export default PromoTickerContainer;
