import AppClient from '../../assets/images/appClient.png';
import { Button } from 'react-bootstrap';
import { FaGooglePlay, FaApple } from 'react-icons/fa';
import Google from '../../assets/images/googleplay.png';
import AppleStore from '../../assets/images/AppStore.png';
import { useMediaQuery } from 'react-responsive';

import CheckList from './CheckList';
import './GigabyteClient.css';

const GigabyteClient = () => {

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const features = [
    "Baixar faturas",
    "Gerenciar sua conta",
    "Ver histórico de pagamentos",
    "Desbloqueio de internet",
    "Suporte ao cliente"
  ];
  return (
    <>
      <div className="container-fluid gigaclient-background">
        <div className="container">
          <div className='row'>
            <div className="col-sm-12 col-md-4">
              <h2 className="text-left my-4">Funcionalidades do App</h2>
              <CheckList items={features} />
            </div>
            <div className="col-sm-12 col-md-4 text-center-title">
              <h2>Gigabyte Cliente</h2>
              <h3>Faturas na palma da sua mão</h3>
              <h4>App Gigabyte Cliente para iOS & Android.</h4>
              <div className="download-button-app">
                <div className="store">
                  <a href="https://play.google.com/store/apps/details?id=br.com.badbit.gigabytetelecom&hl=pt_BR" target="_blank" rel="noopener noreferrer">
                    <img src={Google} alt="" />
                  </a>
                  <a href="https://apps.apple.com/br/app/gigabyte-telecom-cliente/id1620548702" target="_blank" rel="noopener noreferrer">
                    <img src={AppleStore} alt="" />
                  </a>
                </div>
                {/* <Button variant="dark" className="button-app">
                  <FaGooglePlay className="icon" /> Google Play
                </Button>
                <Button variant="dark" className="button-app">
                  <FaApple className="icon" /> App Store
                </Button> */}
              </div>
            </div>
            <div className="col-sm-12 col-md-4">
              <div className='gigaclient image-right'>
                {isMobile ? <div></div> : <img src={AppClient} alt="giga cliente" />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default GigabyteClient;